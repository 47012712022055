import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { gsap } from "gsap";
import { debounce, throttle } from "lodash";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import TransitionLink from "gatsby-plugin-transition-link";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import { rgba } from "polished";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { JV_WIDTH_SCALE, JamunVerse } from "./JamunVerse";
import { Text } from "../Text";

import { JV_Z_INDICES } from "./constants";
import { CancelButton } from "../CancelButton";
import { IAnyPlanet } from "./types";
import { Nav } from "./Nav";
import { Planets } from "./Planets";
import { GjTextSpreadOut } from "../GjTextSpreadOut";
import { JvScrollButton } from "../JvScrollButton";
import { pxToRem } from "../../utils/px-to-rem";
import Portal from "../Portal";
import { useImagesLoaded } from "../../utils/use-images-loaded";

const PLANET_TEXT_EXIT_TIMEOUT = 750;
const INITIAL_SCALE = 0.01;
const BASE_DURATION_FOR_ENTRY_ANIMATION = 1;

export const AllPlanetsWithJamunverse = () => {
    const [openPlanet, setOpenPlanet] = useState<IAnyPlanet>(null);
    const [prevOpenPlanet, setPrevOpenPlanet] = useState<IAnyPlanet>(null);
    const mainRef = useRef<HTMLDivElement>(null);
    const [scrollValue, setScrollValue] = useState(0);
    const [hotspotsEnabled, setHotspotsEnabled] = useState(false);
    const [portalReady, setPortalReady] = useState(false);
    const [planetPortalsReady, setPlanetPortalsReady] = useState(false);
    const [planetExitTimeout, setPlanetExitTimeout] = useState<number>(0);
    const scrollAnimation = useRef<GSAPTimeline>();

    const [pageReady, setPageReady] = useState(false);

    const [scrollAllowed, setScrollAllowed] = useState(false);

    const openPlanetSetter = useCallback(
        (planet: IAnyPlanet) => {
            setPrevOpenPlanet(openPlanet);
            if (!planet) {
                setPlanetExitTimeout(PLANET_TEXT_EXIT_TIMEOUT);
            } else {
                setPlanetExitTimeout(0);
            }
            setOpenPlanet(planet);
        },
        [openPlanet]
    );

    const [scrollButtonState, setScrollButtonState] =
        useState<"scroll" | "trivia">("scroll");

    const [pantuaTextActiveTl, setPantuaTextActiveTl] = useState(
        gsap.timeline({})
    );
    const [jhurraTextActiveTl, setJhurraTextActiveTl] = useState(
        gsap.timeline({})
    );
    const [gulabjamunTextActiveTl, setGulabjamunTextActiveTl] = useState(
        gsap.timeline({})
    );
    const [rasgullaTextActiveTl, setRasgullaTextActiveTl] = useState(
        gsap.timeline({})
    );
    const [kalajamTextActiveTl, setKalajamTextActiveTl] = useState(
        gsap.timeline({})
    );
    const [ledikeniTextActiveTl, setLedikeniTextActiveTl] = useState(
        gsap.timeline({})
    );

    const [pantuaPlanetActiveTl, setPantuaPlanetActiveTl] = useState(
        gsap.timeline({})
    );
    const [jhurraPlanetActiveTl, setJhurraPlanetActiveTl] = useState(
        gsap.timeline({})
    );
    const [gulabjamunPlanetActiveTl, setGulabjamunPlanetActiveTl] = useState(
        gsap.timeline({})
    );
    const [rasgullaPlanetActiveTl, setRasgullaPlanetActiveTl] = useState(
        gsap.timeline({})
    );
    const [kalajamPlanetActiveTl, setKalajamPlanetActiveTl] = useState(
        gsap.timeline({})
    );
    const [ledikeniPlanetActiveTl, setLedikeniPlanetActiveTl] = useState(
        gsap.timeline({})
    );

    const [pantuaFullTl, setPantuaFullTl] = useState(gsap.timeline({}));
    const [jhurraFullTl, setJhurraFullTl] = useState(gsap.timeline({}));
    const [gulabjamunFullTl, setGulabjamunFullTl] = useState(gsap.timeline({}));
    const [rasgullaFullTl, setRasgullaFullTl] = useState(gsap.timeline({}));
    const [kalajamFullTl, setKalajamFullTl] = useState(gsap.timeline({}));
    const [ledikeniFullTl, setLedikeniFullTl] = useState(gsap.timeline({}));

    const activeTlMap = useMemo(
        () => ({
            pantua: pantuaTextActiveTl,
            jhurra: jhurraTextActiveTl,
            gulabjamun: gulabjamunTextActiveTl,
            "rasgulla-sun": rasgullaTextActiveTl,
            kalajam: kalajamTextActiveTl,
            ledikeni: ledikeniTextActiveTl
        }),
        [
            pantuaTextActiveTl,
            jhurraTextActiveTl,
            gulabjamunTextActiveTl,
            rasgullaTextActiveTl,
            kalajamTextActiveTl,
            ledikeniTextActiveTl
        ]
    );

    const activePlanetTlMap = useMemo(
        () => ({
            pantua: pantuaPlanetActiveTl,
            jhurra: jhurraPlanetActiveTl,
            gulabjamun: gulabjamunPlanetActiveTl,
            "rasgulla-sun": rasgullaPlanetActiveTl,
            kalajam: kalajamPlanetActiveTl,
            ledikeni: ledikeniPlanetActiveTl
        }),
        [
            pantuaPlanetActiveTl,
            jhurraPlanetActiveTl,
            gulabjamunPlanetActiveTl,
            rasgullaPlanetActiveTl,
            kalajamPlanetActiveTl,
            ledikeniPlanetActiveTl
        ]
    );

    const fullTlMap = useMemo(
        () => ({
            pantua: pantuaFullTl,
            jhurra: jhurraFullTl,
            gulabjamun: gulabjamunFullTl,
            "rasgulla-sun": rasgullaFullTl,
            kalajam: kalajamFullTl,
            ledikeni: ledikeniFullTl
        }),
        [
            pantuaFullTl,
            jhurraFullTl,
            gulabjamunFullTl,
            rasgullaFullTl,
            kalajamFullTl,
            ledikeniFullTl
        ]
    );

    const fullTlSetters = useMemo(
        () => ({
            pantua: setPantuaFullTl,
            jhurra: setJhurraFullTl,
            gulabjamun: setGulabjamunFullTl,
            "rasgulla-sun": setRasgullaFullTl,
            kalajam: setKalajamFullTl,
            ledikeni: setLedikeniFullTl
        }),
        []
    );
    const planets = [
        "pantua",
        "jhurra",
        "gulabjamun",
        "rasgulla-sun",
        "kalajam",
        "ledikeni"
    ];

    const throttledScrollValue = debounce((value) => {
        setScrollValue(value);
    }, 100);

    const [isMounted, setIsMounted] = React.useState(false);

    const { imagesLoaded } = useImagesLoaded();

    useLayoutEffect(() => {
        const setPageMounted = () => {
            setIsMounted(true);
        };
        if (document.readyState === "complete") {
            setPageMounted();
        } else {
            window.addEventListener("load", setPageMounted);
        }

        return () => {
            window.removeEventListener("load", setPageMounted);
        };
    }, []);

    // Initial timeline and stuff
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
        gsap.registerPlugin(Draggable, InertiaPlugin);
        const INITIAL_SCROLL = window.innerWidth * 0.95;
        const ctx = gsap.context(() => {
            const hotspots = document.querySelectorAll(".hotspot");

            scrollAnimation.current = gsap
                .timeline({
                    scrollTrigger: {
                        scrub: true,
                        trigger: mainRef.current,
                        pin: true,
                        // pinType: "transform",
                        start: "top top",
                        end: `+=${window.innerHeight * 3}`,
                        onUpdate: (self) => {
                            throttledScrollValue(self.progress);

                            if (self.progress > 0.6) {
                                setScrollButtonState("trivia");
                            } else {
                                setScrollButtonState("scroll");
                            }
                        }
                    }
                })
                .fromTo(
                    "#jamunverse",
                    {
                        x: () => {
                            return -INITIAL_SCROLL;
                        }
                    },
                    {
                        x: () => {
                            return -(
                                document.querySelector("#jamunverse")
                                    .clientWidth - window.innerWidth
                            );
                        }
                    }
                )
                .to(
                    ".gj-jv-stars",
                    {
                        x: () => {
                            return (
                                (document.querySelector("#jamunverse")
                                    .clientWidth -
                                    window.innerWidth -
                                    INITIAL_SCROLL) *
                                0.7
                            );
                        }
                    },
                    "="
                );

            gsap.to(window, {
                duration: 0.01
                // scrollTo: INITIAL_SCROLL
            }).then(() => {
                ScrollTrigger.refresh();

                gsap.to(document.body, {
                    clearProps: "overflow"
                }).then(() => {
                    gsap.set(document.body, {
                        overflowY: "hidden"
                    });
                });
            });

            if (
                portalReady &&
                planetPortalsReady &&
                isMounted &&
                imagesLoaded
            ) {
                gsap.timeline({
                    delay: 0
                })
                    // .to("#jamunverse", {
                    //     autoAlpha: 1,
                    //     duration: 0,
                    //     ease: "power3.out"
                    // })
                    .to(document.querySelector("#loading-text"), {
                        autoAlpha: 0,
                        duration: 0.5
                    })
                    .to(
                        mainRef.current,
                        {
                            duration: 0.5
                        },
                        "<"
                    )

                    .add(
                        gsap
                            .timeline({})
                            .fromTo(
                                "#jamunverse",
                                {
                                    autoAlpha: 0
                                },
                                {
                                    autoAlpha: 1,
                                    duration:
                                        BASE_DURATION_FOR_ENTRY_ANIMATION * 2
                                }
                            )
                            .to(
                                "#jamunverse",
                                {
                                    immediateRender: true,
                                    scale: 0.8,
                                    duration:
                                        BASE_DURATION_FOR_ENTRY_ANIMATION * 5,
                                    ease: "linear"
                                },
                                "="
                            )
                            .fromTo(
                                "#jamunverse",
                                {
                                    immediateRender: true,
                                    scale: 0.8,
                                },
                                {
                                    immediateRender: true,
                                    scale: 1,
                                    duration: BASE_DURATION_FOR_ENTRY_ANIMATION * 2.5,
                                    ease: "power1.out"
                                }
                            )
                    )
                    .to(
                        document.querySelectorAll(".gj-text-spread-out"),
                        {
                            autoAlpha: 1,
                            duration: BASE_DURATION_FOR_ENTRY_ANIMATION * 2.5,
                            delay: 0.5,
                            ease: "power3.in"
                        },
                        "<+=150%"
                    )
                    .from(
                        ".gj-jv-stars",
                        {
                            autoAlpha: 0
                        },
                        "<-=10%"
                    )
                    .to(document.querySelectorAll(".gj-text-spread-out"), {
                        autoAlpha: 0,
                        duration: BASE_DURATION_FOR_ENTRY_ANIMATION * 1.25
                    })
                    .then(() => {
                        setHotspotsEnabled(true);
                        gsap.to(hotspots, {
                            autoAlpha: 1
                        });
                        gsap.to(".scroll-button", {
                            autoAlpha: 1
                        });

                        const st = scrollAnimation.current.scrollTrigger;
                        const tracker = InertiaPlugin.track(
                            scrollAnimation.current,
                            "progress"
                        )[0]; // tracks velocity of progress
                        const draggable = new Draggable(
                            document.createElement("div"),
                            {
                                // use a proxy element
                                trigger: mainRef.current,
                                onPress() {
                                    // gsap.killTweensOf(animation);
                                    // eslint-disable-next-line react/no-this-in-sfc
                                    this.startProgress = st.scroll();
                                },
                                onDrag() {
                                    const change =
                                        draggable.startX - draggable.x;
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    st.scroll(draggable.startProgress + change);
                                },
                                onRelease() {
                                    const velocity = tracker.get("progress");
                                    gsap.to(window, {
                                        scrollTo:
                                            st.scroll() +
                                            velocity * window.innerHeight,
                                        // onComplete: () => timer.restart(true),
                                        overwrite: true,
                                        ease: "power2.out"
                                    });
                                }
                            }
                        );
                        setScrollAllowed(true);
                        setPageReady(true);
                        // gsap.set("#jamunverse", { clearProps: "all" });
                        // gsap.set(mainRef.current, {
                        //     overflow: "scroll"
                        // });
                    });
            }
        }, mainRef);

        return () => {
            ctx.revert();
        };
    }, [portalReady, planetPortalsReady, isMounted, imagesLoaded]);

    // This hook creates the active timeline for when a planet is activated
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            if (planetPortalsReady) {
                // setHotspotsEnabled(true);
                planets.forEach((planet) => {
                    const nonOpenPlanets = document.querySelector(`.planet`);
                    const _openPlanetContainer = document.querySelector(
                        `.${planet}-container`
                    );

                    const _openPlanetTextContainer = document.querySelector(
                        `#${planet}-pilowlava-text-container`
                    );

                    const hotspots = document.querySelectorAll(".hotspot");
                    const tl = gsap.timeline({
                        paused: true,
                        defaults: {
                            duration: 0.2
                        }
                    });
                    tl.set(
                        nonOpenPlanets,
                        {
                            zIndex: JV_Z_INDICES.inactivePlanets
                        },
                        "="
                    );
                    // tl.set(hotspots, {
                    //     display: "none",
                    // }, "=")
                    

                    tl.set(_openPlanetContainer, {
                        // position: "fixed",
                        zIndex: JV_Z_INDICES.activePlanet
                    });

                    tl.set(document.querySelector(".blur-overlay"), {
                        zIndex: JV_Z_INDICES.overlay
                    });
                    tl.set(document.querySelector(".scroll-button"), {
                        zIndex: JV_Z_INDICES.inactivePlanets
                    });

                    tl.set(document.querySelector("#menu-button"), {
                        zIndex: 200
                    });

                    tl.set(_openPlanetTextContainer, {
                        zIndex: JV_Z_INDICES.activePlanet
                    });

                    tl.to(
                        hotspots,
                        {
                            autoAlpha: 0,
                            duration: 0.2,
                            ease: "power1.in"
                        },
                        "="
                    );

                    tl.add(
                        gsap.to(document.querySelector("#cancel-button"), {
                            autoAlpha: 1,
                            duration: 0.2
                        }),
                        ">"
                    );

                    tl.add(
                        gsap.to(document.querySelector("#menu-button"), {
                            autoAlpha: 1,
                            duration: 0.2
                        }),
                        "="
                    );

                    
                    tl.to(document.querySelector(".blur-overlay"), {
                        autoAlpha: 1
                    }, "=");
                    

                    tl.add(activeTlMap[planet].play(), "=");

                    tl.add(activePlanetTlMap[planet].play(), "=");

                    

                    fullTlSetters[planet](tl);
                });
            }
        }, mainRef);

        return () => ctx.revert();
    }, [
        planetPortalsReady,
        activeTlMap.pantua,
        activeTlMap.gulabjamun,
        activeTlMap["rasgulla-sun"],
        activeTlMap.jhurra,
        activeTlMap.kalajam,
        activeTlMap.ledikeni,
        activePlanetTlMap.pantua,
        activePlanetTlMap.gulabjamun,
        activePlanetTlMap["rasgulla-sun"],
        activePlanetTlMap.jhurra,
        activePlanetTlMap.kalajam,
        activePlanetTlMap.ledikeni
    ]);

    // This hook runs when a planet is opened or closed
    useLayoutEffect(() => {
        // this runs when navigating to the same planet
        if (prevOpenPlanet === openPlanet) return;

        // this runs when opening a planet
        if (openPlanet) {
            setScrollAllowed(false);
            const prevPlanetTl = fullTlMap[prevOpenPlanet] || gsap.timeline({});
            const currentPlanetTl = fullTlMap[openPlanet];
            prevPlanetTl
                .timeScale(1000)
                .reverse()
                .then(() => {
                    currentPlanetTl.timeScale(1).play();
                });
        } else {
            // this runs when closing an open planet
            const prevPlanetTl = fullTlMap[prevOpenPlanet] || gsap.timeline({});
            const childTimelines = prevPlanetTl.getChildren(false, false, true);

            // Get the text timeline
            const textTimeline = (childTimelines as GSAPTimeline[]).filter(
                (tl) => {
                    return tl.data?.instanceof?.includes("PilowlavaText");
                }
            );

            // Get the text body timeline
            const textBodyTimeline = (textTimeline[0]
                ?.getChildren(true, true, false)
                .filter((tl) => {
                    return tl.data?.instanceof?.includes("PilowlavaTextBody");
                }) || [])[0];

            const textTimelineStartTime = textTimeline[0]?.startTime();
            const modifiedTextTimeline =
                textTimeline[0]?.remove(textBodyTimeline);
            gsap.timeline({})
                .add(
                    gsap.to(textBodyTimeline, {
                        progress: 0,
                        duration: 0.25,
                        ease: "power1.out"
                    })
                )
                .add(
                    prevPlanetTl
                        .remove(textTimeline)
                        .add(modifiedTextTimeline, textTimelineStartTime)
                        .tweenFromTo(prevPlanetTl.totalDuration(), 0, {
                            ease: "power4.out",
                            duration: 1
                        })
                )
                .then(() => {
                    const scroller = scrollAnimation.current.scrollTrigger;
                    scroller.scroll(scroller.scroll() + 1);
                    setScrollAllowed(true);
                });
        }
    }, [openPlanet, prevOpenPlanet]);

    useEffect(() => {
        if (scrollAllowed) {
            gsap.set(document.body, {
                overflowY: "scroll"
            });
        } else {
            gsap.to(document.body, {
                clearProps: "overflow"
            }).then(() => {
                gsap.set(document.body, {
                    overflowY: "hidden"
                });
            });
        }
    }, [scrollAllowed]);

    const EXIT_ANIM_DURATION = 1.5;

    const exitAnimation = () => {
        const ctx = gsap.context(() => {
            gsap.timeline({
                defaults: {
                    duration: EXIT_ANIM_DURATION
                }
            }).to(mainRef.current, {
                autoAlpha: 0,
                ease: "power4.inOut"
            });
        }, mainRef);
    };
    const onPlanetPortalsReady = useCallback(() => {
        setPlanetPortalsReady(true);
    }, []);

    const onClickJvScrollButton = useCallback(() => {
        if (scrollButtonState === "trivia") {
            const btn =
                gsap.utils.toArray<HTMLAnchorElement>(".to-trivia-link")[0];
            btn.click();
        } else if (pageReady) {
            const scroller = scrollAnimation.current.scrollTrigger;

            gsap.to(window, {
                duration: 0.5,
                scrollTo: scroller.scroll() + window.innerHeight * 0.8
            });
        }
    }, [scrollButtonState, pageReady]);

    return (
        <div>
            <div
                id="loading-text"
                css={{
                    zIndex: 200,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Text color="white" variant="title">
                    ...
                </Text>
            </div>
            <div
                ref={mainRef}
                css={{
                    overflow: "hidden",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    // zIndex: JV_Z_INDICES.inactivePlanets,
                    // ".planet": {
                    //     opacity: 0,
                    // },
                    ".hotspot": {
                        opacity: 0
                    },
                    ".text-with-hotspot": {
                        // opacity: 0,
                    }
                }}
            >
                <AniLink
                    className="to-trivia-link"
                    duration={1.2}
                    cover
                    to="/fun-facts"
                    bg="#000000"
                >
                    {/* <Link to="/fun-facts/fact-1-puzzle"> */}
                    <div
                        css={{
                            display: "none"
                        }}
                    >
                        <button />
                    </div>
                </AniLink>
                <div
                    id="jamunverse"
                    css={{
                        transform: `scale(${INITIAL_SCALE})`,
                        // transformOrigin: "left",
                        // opacity: 0,
                        // position: "fixed",
                        willChange: "transform",
                        transformOrigin: "48% center",

                        top: 0,
                        left: 0,
                        width: `${100 * JV_WIDTH_SCALE}%`,
                        height: "100%"
                        // overflow: "scroll"
                        // opacity: 0,
                    }}
                >
                    <Planets
                        exitTimeout={planetExitTimeout}
                        hotspotsEnabled={hotspotsEnabled}
                        scrollValue={scrollValue}
                        openPlanet={openPlanet}
                        setOpenPlanet={openPlanetSetter}
                        setRasgullaTextActiveTl={setRasgullaTextActiveTl}
                        setPantuaTextActiveTl={setPantuaTextActiveTl}
                        setJhurraTextActiveTl={setJhurraTextActiveTl}
                        setGulabjamunTextActiveTl={setGulabjamunTextActiveTl}
                        setKalajamTextActiveTl={setKalajamTextActiveTl}
                        setLedikeniTextActiveTl={setLedikeniTextActiveTl}
                        setRasgullaPlanetActiveTl={setRasgullaPlanetActiveTl}
                        setPantuaPlanetActiveTl={setPantuaPlanetActiveTl}
                        setJhurraPlanetActiveTl={setJhurraPlanetActiveTl}
                        setGulabjamunPlanetActiveTl={
                            setGulabjamunPlanetActiveTl
                        }
                        setKalajamPlanetActiveTl={setKalajamPlanetActiveTl}
                        setLedikeniPlanetActiveTl={setLedikeniPlanetActiveTl}
                        onPlanetPortalsReady={onPlanetPortalsReady}
                    />
                    <JamunVerse zIndex={JV_Z_INDICES.chaashni}>
                        <div />
                    </JamunVerse>
                    <div
                        css={{
                            position: "fixed",
                            zIndex: JV_Z_INDICES.chaashni,
                            // backgroundColor: `${rgba("#1E1E1E", 0.92)}`,
                            // filter: "blur(60px)",
                            background: rgba("#3C231D", 0.96),
                            opacity: 0,
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100vh",
                            overflow: "hidden",
                            overscrollBehavior: "none",
                            willChange: "transform"
                        }}
                        className="blur-overlay"
                    />
                </div>

                {/* <div */}
                {/*    onScroll={(e) => { */}
                {/*        const jamunVerse = */}
                {/*            document.querySelector<HTMLDivElement>("#jamunverse"); */}
                {/*        jamunVerse.style.transform = `translateX(-${e.target.scrollLeft}px)`; */}
                {/*        // console.log(e.target.scrollLeft); */}
                {/*    }} */}
                {/*    css={{ */}
                {/*        position: "fixed", */}
                {/*        zIndex: JV_Z_INDICES.scroller, */}
                {/*        // filter: "blur(60px)", */}
                {/*        opacity: 0, */}
                {/*        top: 0, */}
                {/*        left: 0, */}
                {/*        width: "100%", */}
                {/*        height: "100vh", */}
                {/*        overflow: "scroll", */}
                {/*        willChange: "transform" */}
                {/*    }} */}
                {/*    className="scroller" */}
                {/* > */}
                {/*    <div */}
                {/*        css={{ */}
                {/*            opacity: 0, */}
                {/*            width: "200%", */}
                {/*            height: "100vh" */}
                {/*        }} */}
                {/*    /> */}
                {/* </div> */}
                <Portal
                    onMount={() => {
                        setPortalReady(true);
                    }}
                    selector="#jamunverse-main-portal"
                >
                    <div
                        id="cancel-button"
                        css={{
                            opacity: 0,
                            // transition: "all 0.5s",
                            position: "fixed",
                            left: "2vw",
                            top: "2vw",
                            zIndex: 200
                        }}
                    >
                        <CancelButton onClick={() => openPlanetSetter(null)} />
                    </div>
                    <div
                        id="menu-button"
                        css={{
                            opacity: 0,
                            // transition: "all 0.5s",
                            position: "fixed",
                            right: "2vw",
                            top: "2vw",
                            zIndex: -1
                        }}
                    >
                        <Nav setOpenPlanet={openPlanetSetter} />
                    </div>

                    <div
                        className="scroll-button"
                        css={{
                            opacity: 0,
                            position: "fixed",
                            right: pxToRem(24),
                            bottom: pxToRem(24),
                            zIndex: JV_Z_INDICES.activePlanet
                        }}
                    >
                        <JvScrollButton
                            state={scrollButtonState}
                            onClick={onClickJvScrollButton}
                        />
                    </div>
                    <div
                        css={{
                            ".gj-text-spread-out": {
                                opacity: 0
                            }
                        }}
                    >
                        <GjTextSpreadOut />
                    </div>
                </Portal>
            </div>
        </div>
    );
};

export default AllPlanetsWithJamunverse;
